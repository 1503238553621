import VueI18n from '@/plugins/vue-i18n';

export const importEndpoints = {
  TRANSACTIONS: 'transactions',
  ITEM_INFO: 'itemInfo',
  BOM: 'bom',
  TO_RECEIVE: 'toReceive',
  TO_SHIP: 'toShip'
};

export const exportEndpoints = {
  EXPORT_PO: 'exportPO',
  EXPORT_TO: 'exportTO',
  EXPORT_MO: 'exportMO'
};

export const importEndpointsList = {
  [importEndpoints.TRANSACTIONS]: {
    label: VueI18n.t('Web.CustomApi.Input.Transactions')
  },
  [importEndpoints.ITEM_INFO]: {
    label: VueI18n.t('Web.CustomApi.Input.ItemInfo')
  },
  [importEndpoints.TO_SHIP]: {
    label: VueI18n.t('Web.CustomApi.Input.ToShip')
  },
  [importEndpoints.TO_RECEIVE]: {
    label: VueI18n.t('Web.CustomApi.Input.ToReceive')
  },
  [importEndpoints.BOM]: {
    label: VueI18n.t('Web.CustomApi.Input.BOM')
  }
};

export const exportEndpointsList = {
  [exportEndpoints.EXPORT_PO]: {
    label: VueI18n.t('Web.CustomApi.Input.PlannedOrders')
  },
  [exportEndpoints.EXPORT_TO]: {
    label: VueI18n.t('Web.CustomApi.Input.TransferOrders')
  },
  [exportEndpoints.EXPORT_MO]: {
    label: VueI18n.t('Web.CustomApi.Input.ManufactureOrders')
  }
};