<template>
  <div class="toolbar">
    <div class="toolbar__container">
      <div class="toolbar__col">
        <SlButton
          v-tooltip="getTooltip($t('Web.Integrations.Tooltips.BackToIntegrations'))"
          variant="tertiary"
          color="grey"
          size="xs"
          icon
          @click="handleBackToIntegrations"
        >
          <icon
            data="@icons/chevron_left.svg"
            class="fill-off size-20"
          />
        </SlButton>
        <div
          class="toolbar__integration-name heading-5 grey-80"
          :title="activeIntegration.name"
        >
          {{ activeIntegration.name }}
        </div>
      </div>
      <div class="toolbar__col toolbar__col--steps">
        <IntegrationStep
          v-for="(step, index) in steps"
          :key="step.type"
          v-tooltip="getTooltip({
            content: step.tooltip,
            disabled: isAvailable(index)
          })"
          :active="step.active"
          :completed="isCompleted(index)"
          :disabled="isDisabled(index)"
          :available="isAvailable(index)"
          :divider="index < steps.length - 1"
          @click="handleChangeStep(step)"
        >
          {{ $t(`Web.Integrations.Steps.${step.type}`) }}
        </IntegrationStep>
      </div>
      <div class="toolbar__col toolbar__col--actions">
        <SlButton
          v-if="!isFirstStep"
          variant="secondary"
          color="grey"
          :disabled="!previousStep"
          @click="handleChangeStep(previousStep, 'prev')"
        >
          <template #prepend>
            <icon
              data="@icons/chevron_left.svg"
              class="fill-off size-16 color-grey-80"
            />
          </template>
          {{ $t('Web.Integrations.Actions.BtnBack') }}
        </SlButton>
        <div
          v-if="!isLastStep"
          v-tooltip="getTooltip({
            content: $t('Web.Integrations.Tooltips.CompleteCurrentStep'),
            disabled: !isNextDisabled,
            style: 'width: 250px;'
          })"
        >
          <SlButton
            :disabled="isNextDisabled"
            @click="handleChangeStep(nextStep, 'next')"
          >
            {{ $t('Web.Integrations.Actions.BtnNext') }}
            <template #append>
              <icon
                data="@icons/chevron_right.svg"
                class="fill-off size-16 color-white-stroke"
              />
            </template>
          </SlButton>
        </div>
        <SlButton
          v-if="isLastStep"
          @click="handleFinish"
        >
          {{ $t('Web.Integrations.Actions.BtnFinish') }}
        </SlButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import IntegrationStep from '@/components/Integrations/IntegrationStep.vue';
import { routeNames } from '@/config/router/router.config';
import { stepStatuses } from '@/config/integrations';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'IntegrationToolbar',
  components: {
    IntegrationStep
  },
  data() {
    return {
      getTooltip
    };
  },
  computed: {
    ...mapState({
      activeIntegration: state => state.integrations.active_integration || {},
      steps: state => state.integrations.active_steps || []
    }),
    ...mapGetters({
      activeStepIndex: 'integrations/activeStepIndex',
      isActiveStepCompleted: 'integrations/isActiveStepCompleted'
    }),
    nextStep() {
      return this.steps.length - 1 > this.activeStepIndex && this.steps[this.activeStepIndex + 1];
    },
    previousStep() {
      return this.activeStepIndex > 0 && this.steps[this.activeStepIndex - 1];
    },
    isLastStep() {
      return this.steps.length - 1 === this.activeStepIndex;
    },
    isFirstStep() {
      return this.activeStepIndex === 0;
    },
    isNextDisabled() {
      return !this.nextStep || !this.isActiveStepCompleted;
    }
  },
  watch: {
    activeStepIndex(index) {
      if (!this.steps[index]?.type) {
        return;
      }

      this.$router.push({
        name: this.$sl_routeName,
        params: {
          ...this.$route.params,
          step: this.steps[index].type
        }
      });
    }
  },
  methods: {
    isCompleted(index) {
      if (index < 0) {
        return true;
      }

      return this.steps[index]?.status === stepStatuses.COMPLETED;
    },
    isAvailable(index) {
      return this.steps[index]?.status !== stepStatuses.NO_AVAILABLE;
    },
    isDisabled(index) {
      return !this.isCompleted(index - 1) && this.isAvailable(index - 1);
    },
    handleBackToIntegrations() {
      this.$router.push({
        name: routeNames.INTEGRATIONS
      });
    },
    handleChangeStep(step = {}, direction = '') {
      if (!step) {
        return;
      }

      const isAvailable = step.status !== stepStatuses.NO_AVAILABLE;
      const skipIndex = direction === 'next' ? 1 : -1;
      const stepIndex = this.steps.findIndex(item => item.type === step.type);
      const availableIndex = stepIndex !== -1 ? stepIndex : 0;
      const indexTo = isAvailable ? availableIndex : availableIndex + skipIndex;

      if (indexTo === this.activeStepIndex) {
        return;
      }

      this.$emit('step-change', indexTo);
    },
    handleFinish() {
      this.$emit('finish');
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/style/components/integrations/integration-toolbar.scss';
</style>
