<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          {{ $t('Web.Spreadsheet.TabMatchSheets') }}
        </template>
        <template #subtitle>
          {{ $t('Web.Spreadsheet.MatchSheetsSubtitle') }}
        </template>
        <IntegrationMatch
          :left-label="$t('Web.Spreadsheet.MatchStreamlineSheet')"
          :middle-label="fileMatchLabel"
          :right-label="$t('Web.Spreadsheet.MatchTableSheet')"
        >
          <div
            v-for="tab in importTabs"
            :key="tab.value"
            class="integration-match-row"
          >
            <div class="w-150 body-1 grey-80">
              {{ tab.label }}
            </div>
            <SlSelect
              v-if="isMultipleFiles"
              :value="getFileValue(tab.value)"
              :placeholder="$t('Web.Spreadsheet.SelectFile')"
              :options="files"
              track-by="id"
              label="name"
              class="w-240"
              allow-empty
              inline
              @input="(value) => updateFileValue(tab.value, value)"
            />
            <SlSelect
              v-tooltip="getSheetTooltip(tab.value)"
              :value="getSheetValue(tab.value)"
              :placeholder="$t('Web.Spreadsheet.SelectSheet')"
              :options="getSheetOptions(tab.value)"
              :disabled="getIsSheetDisabled(tab.value)"
              :loading="isSheetsLoading"
              label="name"
              track-by="index"
              class="w-240"
              allow-empty
              inline
              @input="(value) => updateSheetValue(tab.value, value)"
            />
          </div>
        </IntegrationMatch>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import IntegrationMatch from '@/components/Integrations/IntegrationMatch.vue';
import { importTabs, matchKeys } from '@/config/integrations/spreadsheet.config';
import { fileFormats } from '@/config/utils/fileUpload.config';
import { stepStatuses } from '@/config/integrations';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'MatchSheets',
  components: {
    IntegrationContent,
    IntegrationMatch
  },
  data() {
    return {
      importTabs: importTabs(this),
      isSheetsLoading: false
    };
  },
  computed: {
    ...mapState({
      filesSheets: (state) => state.integrations.spreadsheet.files_sheets,
      tabData: state => state.integrations.spreadsheet.match_sheets
    }),
    ...mapGetters({
      files: 'integrations/spreadsheet/files',
      isMultipleFiles: 'integrations/spreadsheet/isMultipleFiles',
      fileByFileIdMap: 'integrations/spreadsheet/fileByFileIdMap'
    }),
    firstFileId() {
      return this.files[0]?.id ?? null;
    },
    fileMatchLabel() {
      if (this.isMultipleFiles) {
        return this.$t('Web.Spreadsheet.MatchFile');
      }

      return '';
    }
  },
  mounted() {
    if (!this.isMultipleFiles && this.firstFileId !== null) {
      this.loadSheetList(this.firstFileId);
    }
  },
  methods: {
    ...mapActions({
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      updateMatchedSheet: 'integrations/spreadsheet/updateMatchedSheet',
      fetchSheetList: 'integrations/spreadsheet/fetchSheetList',
      resetWarnings: 'integrations/spreadsheet/resetWarnings'
    }),
    async loadSheetList(fileId) {
      try {
        this.isSheetsLoading = true;

        await this.fetchSheetList(fileId);
      } finally {
        this.isSheetsLoading = false;
      }
    },
    checkIsMatchedCsv(key) {
      const file = this.fileByFileIdMap[this.getFileValue(key)];

      return file && file.type === fileFormats.csv;
    },
    getSheetTooltip(key) {
      return getTooltip({
        content: this.$t('Web.Spreadsheet.TooltipCsvSheet'),
        disabled: !this.checkIsMatchedCsv(key)
      });
    },
    getFileValue(key) {
      return this.tabData[key][matchKeys.FILE];
    },
    getSheetOptions(key) {
      if (this.isMultipleFiles) {
        return this.filesSheets[this.getFileValue(key)] || [];
      }

      return this.filesSheets[this.firstFileId] || [];
    },
    getSheetValue(key) {
      return this.tabData[key][matchKeys.SHEET];
    },
    getIsSheetDisabled(key) {
      if (this.checkIsMatchedCsv(key)) {
        return true;
      }

      if (this.isMultipleFiles) {
        return !this.getFileValue(key);
      }

      return false;
    },
    updateFileValue(key, value) {
      this.updateMatchedSheet({
        key,
        field: matchKeys.FILE,
        value
      });

      if (value) {
        this.loadSheetList(value);
      }

      this.validate();
    },
    updateSheetValue(key, value) {
      this.updateMatchedSheet({
        key,
        field: matchKeys.SHEET,
        value
      });

      this.validate();
    },
    validate() {
      const isValid = this.importTabs.some(({ value: key }) => {
        const tab = this.tabData[key];

        return tab[matchKeys.FILE] !== null && tab[matchKeys.SHEET] !== null;
      });

      const status = isValid ? stepStatuses.COMPLETED : stepStatuses.INCOMPLETE;

      this.setActiveStepStatus(status);
    }
  }
};
</script>
